import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const VentaDetail = () => {
  const { ventaId } = useParams();
  const [venta, setVenta] = useState(null);

  useEffect(() => {
    if (ventaId) {
      fetch(`http://app.construgran.mx/nodeapi/ventas/${ventaId}`)
        .then(response => response.json())
        .then(data => setVenta(data))
        .catch(error => console.error('Error fetching venta details:', error));
    }
  }, [ventaId]);

  if (!venta) return <div>Cargando...</div>;

  return (
    <div>
      <h2>Detalles de la Venta</h2>
      <p><strong>Venta No:</strong> {venta.NO_REFEREN}</p>
      <p><strong>Cliente:</strong> {venta.CLIENTE}</p>
      <p><strong>Importe:</strong> ${venta.IMPORTE}</p>
      <p><strong>Fecha de Emisión:</strong> {new Date(venta.F_EMISION).toLocaleDateString()}</p>
      <h3>Productos:</h3>
      <ul>
        {Array.isArray(venta.PRODUCTOS) && venta.PRODUCTOS.length > 0 ? (
          venta.PRODUCTOS.map(producto => (
            <li key={producto.ARTICULO}>
              Artículo: {producto.OBSERV}, Cantidad: {producto.CANTIDAD}, Precio: ${producto.PRECIO}
            </li>
          ))
        ) : (
          <li>No hay productos para esta venta.</li>
        )}
      </ul>
    </div>
  );
};

export default VentaDetail;
