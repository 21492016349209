import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

// Función para obtener la fecha en formato YYYY-MM-DD según la zona horaria local
const getLocalDateString = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const VentaList = () => {
  const [ventas, setVentas] = useState([]);
  const [startDate, setStartDate] = useState(getLocalDateString(new Date())); // Fecha actual
  const [endDate, setEndDate] = useState(getLocalDateString(new Date())); // Fecha actual
  const navigate = useNavigate();

  const fetchVentas = useCallback(() => {
    fetch(`http://app.construgran.mx/nodeapi/ventas?start=${startDate}&end=${endDate}`)
      .then(response => response.json())
      .then(data => setVentas(data))
      .catch(error => console.error('Error fetching ventas:', error));
  }, [startDate, endDate]);

  useEffect(() => {
    fetchVentas(); // Llamada inicial para obtener ventas del día de hoy
  }, [fetchVentas]);

  const handleVentaClick = (ventaId) => {
    navigate(`/venta/${ventaId}`);
  };

  return (
    <div>
      <h2>Lista de Ventas</h2>
      <div>
        <label>Desde: </label>
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <label>Hasta: </label>
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <button onClick={fetchVentas}>Buscar</button>
      </div>
      <ul>
        {ventas.length > 0 ? (
          ventas.map(venta => (
            <li key={venta.VENTA} onClick={() => handleVentaClick(venta.VENTA)}>
              Venta No: {venta.VENTA}, Usuario: {venta.USUARIO}, Importe: ${venta.IMPORTE}
            </li>
          ))
        ) : (
          <li>No se encontraron ventas en el rango de fechas seleccionado.</li>
        )}
      </ul>
    </div>
  );
};

export default VentaList;
